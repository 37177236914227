import { createApi } from "@reduxjs/toolkit/query/react";
import type {
	AccessGroup,
	AccessGroupAPI,
	AccessGroupObject,
} from "../../service/Types/AccessGroup";
import type { User, UserApiResponseType } from "../../service/Types/UserTypes";
import type {
	DlmCompanyConfig,
	ApiLegalEntity,
	CompanyMart,
	DataLayerUrlsResponse,
	PbiAppInstallation,
	SieValidateResponse,
	DlmLegalEntityMartConfig,
	DlmDataObjectTypes,
	DlmRawMartConfig,
	TemplateApp,
	DatabaseUser,
	DatabaseConnection,
} from "./exopenApiTypes";
import type {
	PipelineApiResponse,
	PipelineResponse,
} from "../../service/pipeline";
import type { Company, CompanyInfo } from "../../service/companies";
import { mapApiUserToUser } from "../../service/users";
import type { PumpConfigUpsertPumpSetting } from "../../service/pumps";
import type { LegacyLegalEntity } from "../../context/LegacyLegalEntityContext";
import { makeBaseQueryWithUnauthentication } from "./baseQuery";
import { serviceUrl } from "../../../env";

export const exopenApi = createApi({
	reducerPath: "exopenApi",
	baseQuery: makeBaseQueryWithUnauthentication({ baseUrl: serviceUrl }),
	tagTypes: [
		"Company",
		"EWR",
		"User",
		"LegalEntity",
		"AccountHierarchy",
		"AccessGroup",
		"CompanyDataLake",
		"Journal",
		"JournalMetadata",
		"ExchangeStrategies",
		"LegalEntityCurrency",
		"LegalEntityAccount",
		"MainPipelineRuns",
		"CurrentUser",
		"CompanyMarts",
		"Pump",
		"SystemUser",
		"DataLayerStatus",
	],
	endpoints: (builder) => ({
		getAvailableObjectsForAccessGroups: builder.query<
			AccessGroupObject[],
			string
		>({
			query: (companyId) =>
				`api/AccessGroups/AvailableObjectsForAccessGroups/${companyId}`,
		}),

		getAccessGroups: builder.query<AccessGroup[], string>({
			query: (companyId) => `api/AccessGroups/GetAccessGroups/${companyId}`,
			transformResponse: (response: AccessGroupAPI[]) => {
				return response.map((accessGroup) => {
					const legalEntities: string[] = [];

					if (typeof accessGroup.settings.legalEntities === "string") {
						try {
							const parsedLegalEntities = JSON.parse(
								accessGroup.settings.legalEntities,
							);
							if (Array.isArray(parsedLegalEntities)) {
								for (const element of parsedLegalEntities) {
									if (typeof element === "string") {
										legalEntities.push(element);
									}
								}
							}
						} catch {
							// ignore invalid json
						}
					}

					return {
						...accessGroup,
						description:
							typeof accessGroup.settings.description === "string"
								? accessGroup.settings.description
								: "",
						colorCode:
							typeof accessGroup.settings.colorCode === "string"
								? accessGroup.settings.colorCode
								: "",
						legalEntities,
					};
				});
			},
			providesTags: (result) =>
				result
					? [
							...result.map(({ groupId }) => ({
								type: "User" as const,
								id: groupId,
							})),
							"AccessGroup",
						]
					: ["AccessGroup"],
		}),

		deleteAccessGroup: builder.mutation<
			void,
			{ companyId: string; groupId: number }
		>({
			query({ companyId, groupId }) {
				return {
					method: "DELETE",
					url: `api/AccessGroups/RemoveAccessGroup/${companyId}/${groupId}`,
					responseHandler: "text",
				};
			},
			invalidatesTags: ["AccessGroup"],
		}),

		addAccessGroup: builder.mutation<
			void,
			{
				companyId: string;
				name: string;
				description: string;
				colorCode: string;
				accessGroupObjects: { objectId: string }[];
				legalEntities: LegacyLegalEntity["groupMasterKey"][];
			}
		>({
			query({
				companyId,
				name,
				description,
				colorCode,
				legalEntities,
				accessGroupObjects,
			}) {
				return {
					url: `api/AccessGroups/AddAccessGroup/${companyId}`,
					method: "POST",
					body: {
						name,
						settings: {
							description,
							colorCode,
							legalEntities: JSON.stringify(legalEntities),
						},
						accessGroupObjects,
					},
					responseHandler: "text",
				};
			},
			invalidatesTags: ["AccessGroup"],
		}),

		editAccessGroup: builder.mutation<
			void,
			{
				companyId: string;
				groupId: number;
				name: string;
				description: string;
				colorCode: string;
				accessGroupObjects: { objectId: string }[];
				legalEntities: LegacyLegalEntity["groupMasterKey"][];
			}
		>({
			query({
				accessGroupObjects,
				colorCode,
				companyId,
				legalEntities,
				description,
				groupId,
				name,
			}) {
				return {
					url: `api/AccessGroups/UpdateAccessGroup/${companyId}/${groupId}`,
					method: "PUT",
					body: {
						name,
						settings: {
							description,
							colorCode,
							legalEntities: JSON.stringify(legalEntities),
						},
						accessGroupObjects,
					},
					responseHandler: "text",
				};
			},
			invalidatesTags: ["AccessGroup"],
		}),

		getCompanyUsers: builder.query<User[], string>({
			query: (companyId) => `User/companies/${companyId}`,
			transformResponse(response: UserApiResponseType[]) {
				return response.map(mapApiUserToUser);
			},
			providesTags: (result) => {
				return result
					? [
							...result.flatMap(({ email, accessGroups }) => {
								return [
									{ type: "User" as const, id: email },
									...accessGroups.map((accessGroup) => {
										return {
											type: "AccessGroup" as const,
											id: accessGroup.groupId,
										};
									}),
								];
							}),
							"User",
						]
					: ["User"];
			},
		}),

		updateUser: builder.mutation<
			void,
			{ companyId: string; email: string; accessGroups: number[] }
		>({
			query: ({ companyId, accessGroups, email }) => {
				return {
					url: `api/AccessGroups/UpdateAccessGroupsOfUser/${companyId}`,
					method: "PUT",
					body: {
						userEmail: email,
						accessGroupIdList: accessGroups,
					},
					responseHandler: "text",
				};
			},
			invalidatesTags: ["User"],
		}),

		deleteUser: builder.mutation<void, { companyId: string; email: string }>({
			query({ companyId, email }) {
				return {
					url: "User/RemoveUserFromCompany",
					method: "PUT",
					body: {
						userEmail: email,
						companyId: Number(companyId),
					},
				};
			},
			invalidatesTags: ["User"],
		}),

		inviteUser: builder.mutation<
			void,
			{ companyId: string; email: string; accessGroups: number[] }
		>({
			query({ companyId, email, accessGroups }) {
				return {
					url: "Invitation/invite",
					method: "POST",
					body: {
						emailAddress: email,
						accessGroupIds: accessGroups,
						companyId: Number(companyId),
					},
				};
			},
			invalidatesTags: ["User"],
		}),

		getDataLayerPbiUrls: builder.query<DataLayerUrlsResponse, string>({
			query: (companyId) => {
				return `api/v1/datalayer/companies/${companyId}/pbi/urls`;
			},
		}),
		getDataLayerPbiStorageParameters: builder.query<
			DataLayerUrlsResponse,
			{ companyDomainId: string }
		>({
			query: ({ companyDomainId }) => {
				return `api/v1/datalayer/${companyDomainId}/pbi/storage-parameters`;
			},
		}),
		getDataLayerPbiTicket: builder.query<PbiAppInstallation, string>({
			query: (companyId) => {
				return `api/v1/datalayer/companies/${companyId}/pbi/ticket`;
			},
		}),
		getDataLayerStatus: builder.query<boolean, string>({
			query: (companyId) => {
				return `api/v1/datalayer/companies/${companyId}`;
			},
			providesTags(result, error, arg) {
				return [{ type: "DataLayerStatus", id: arg }];
			},
		}),
		activateDataLayer: builder.mutation<void, string>({
			query: (companyId) => {
				return {
					url: `api/v1/datalayer/companies/${companyId}/activate`,
					method: "POST",
					body: {},
				};
			},
			invalidatesTags: (result, error, arg) => {
				return [{ type: "DataLayerStatus", id: arg }];
			},
		}),
		deactivateDataLayer: builder.mutation<void, string>({
			query: (companyId) => {
				return {
					url: `api/v1/datalayer/companies/${companyId}/deactivate`,
					method: "POST",
					body: {},
				};
			},
			invalidatesTags: (result, error, arg) => {
				return [{ type: "DataLayerStatus", id: arg }];
			},
		}),
		validateSieFile: builder.mutation<
			SieValidateResponse,
			{ companyId: string; file: Blob }
		>({
			query({ companyId, file }) {
				const body = new FormData();
				body.append("File", file);
				body.append("CompanyId", companyId);

				return {
					method: "POST",
					url: "api/v2/sie/validate",
					body,
				};
			},
		}),

		approveSieFile: builder.mutation<
			void,
			{
				sieId: string;
				companyId: string;
				companyNameFromSie: string;
				organizationNumberFromSie: string;
				runDataLoadOnApprove?: boolean;
			}
		>({
			query({
				companyId,
				companyNameFromSie,
				organizationNumberFromSie,
				sieId,
				runDataLoadOnApprove = true,
			}) {
				return {
					method: "POST",
					url: "api/v2/sie/approve",
					body: {
						companyId,
						sieId,
						CompanyNameFromSie: companyNameFromSie,
						OrganizationNumberFromSie: organizationNumberFromSie,
					},
					params: { runDataLoadOnApprove },
				};
			},
		}),

		deleteSiePeriod: builder.mutation<
			void,
			{
				companyId: string;
				fiscalYearEntityId: string;
			}
		>({
			query({ companyId, fiscalYearEntityId }) {
				return {
					method: "DELETE",
					url: `sie/v1/${companyId}/periods/${fiscalYearEntityId}`,
				};
			},
		}),

		startMainPipeline: builder.mutation<
			void,
			{
				companyId: string;
				leMasterKey?: string;
			}
		>({
			query({ companyId, leMasterKey }) {
				const allUrl = `api/pipeline/companies/${companyId}/start`;
				const pipelineUrl = !leMasterKey
					? allUrl
					: `${allUrl}?leKey=${leMasterKey}`;
				return {
					method: "POST",
					url: pipelineUrl,
					responseHandler: "text",
				};
			},
			invalidatesTags(result, error, arg) {
				return [{ type: "MainPipelineRuns", id: arg.companyId }];
			},
		}),

		stopPipelines: builder.mutation<
			void,
			{
				companyId: string;
			}
		>({
			query({ companyId }) {
				return {
					method: "POST",
					url: `api/pipeline/companies/${companyId}/stop`,
					responseHandler: "text",
				};
			},
			invalidatesTags(result, error, arg) {
				return [{ type: "MainPipelineRuns", id: arg.companyId }];
			},
		}),

		getPipelineRuns: builder.query<
			PipelineResponse[],
			{ companyId: string; hoursBack?: number }
		>({
			query: ({ companyId, hoursBack }) => {
				return {
					url: `api/pipeline/companies/${companyId}/pipelineruns`,
					params: {
						hoursBack,
					},
				};
			},
			transformResponse: (response: PipelineApiResponse[]) => {
				const mappedJson = response.map((x): PipelineResponse => {
					return {
						runId: x.runId,
						isLatest: x.isLatest,
						status: x.status,
						runStart: new Date(x.runStart),
						lastUpdated: new Date(x.lastUpdated),
						runEnd: x.runEnd ? new Date(x.runEnd) : null,
						durationInMs: x.durationInMs,
						parameters: x.parameters,
					};
				});
				return mappedJson;
			},
			providesTags: (result, error, arg) => [
				{
					type: "MainPipelineRuns",
					id: arg.companyId,
					hoursBack: arg.hoursBack,
				},
			],
		}),

		recreatePipelines: builder.mutation<void, { companyId: string }>({
			query({ companyId }) {
				return { method: "PUT", url: `api/Pipeline/companies/${companyId}` };
			},
		}),

		getAllCompanies: builder.query<Company[], void>({
			query() {
				return "api/v2/companies";
			},
			providesTags: ["Pump", "Company"],
		}),

		getCompany: builder.query<CompanyInfo, { companyId: string }>({
			query({ companyId }) {
				return `api/v2/companies/${companyId}`;
			},
			providesTags: ["EWR", "Pump", "Company"],
		}),

		deleteCompany: builder.mutation<void, { companyId: string }>({
			query({ companyId }) {
				return {
					method: "DELETE",
					url: `api/v2/companies/${companyId}`,
				};
			},
			invalidatesTags: ["Pump"],
		}),

		deletePump: builder.mutation<
			void,
			{ companyId: string; pumpId: string; deleteLegalEntities?: boolean }
		>({
			query({ companyId, pumpId, deleteLegalEntities = false }) {
				return {
					method: "DELETE",
					url: `pumps/companyid/${companyId}/pump/${pumpId}?deleteLegalEntities=${deleteLegalEntities}`,
					responseHandler: "text",
				};
			},
			invalidatesTags: ["Pump"],
		}),

		createCompany: builder.mutation<
			{ id: number },
			{ name: string; type: string }
		>({
			query({ name, type }) {
				return {
					url: "api/v3/companies",
					method: "POST",
					params: { blocking: true },
					body: {
						name,
						type,
					},
				};
			},
			invalidatesTags: ["Pump", "Company"],
		}),

		getCurrentUser: builder.query<User, void>({
			query: () => "User/CurrentUser",
			transformResponse(response: UserApiResponseType) {
				return mapApiUserToUser(response);
			},
			providesTags: () => {
				return ["CurrentUser"];
			},
		}),

		createVismaNetCompany: builder.mutation<
			{ id: string },
			{
				companyName: string;
				config: {
					tenantId: string;
					subAccountSegmentIds: {
						costCenterId: number | null;
						projectId: number | null;
					};
					startPump: boolean;
				};
			}
		>({
			query(data) {
				return {
					method: "POST",
					url: "api/v2/companies/vismanet",
					body: data,
				};
			},
			invalidatesTags: () => {
				return ["CurrentUser"];
			},
		}),

		getVismaNetDimensions: builder.query<
			Array<{ name: string; id: string }>,
			{ tenantId: string }
		>({
			query({ tenantId }) {
				return {
					url: "/Pumps/vismanet/client-credentials/list-sub-account-segment-ids",
					headers: {
						tenantId,
					},
				};
			},
		}),

		getVismaNetBranch: builder.query<
			{ name: string; branchId: string },
			{ tenantId: string }
		>({
			query({ tenantId }) {
				return {
					url: "/Pumps/vismanet/client-credentials/branch",
					headers: {
						tenantId,
					},
				};
			},
		}),

		getLegalEntities: builder.query<ApiLegalEntity[], string>({
			query: (companyId) => `/legalentity/v1/${companyId}`,
			providesTags: (result, error, arg) => [{ type: "LegalEntity", id: arg }],
		}),

		deleteLegalEntity: builder.mutation<
			void,
			{ companyId: string; legalEntityId: string }
		>({
			query({ legalEntityId, companyId }) {
				return {
					method: "DELETE",
					url: `/legalentity/v1/${companyId}/${legalEntityId}`,
				};
			},
			invalidatesTags: ["LegalEntity"],
		}),

		getCompanyMarts: builder.query<CompanyMart[], string>({
			query: (companyId) => {
				return `api/Marts/companies/${companyId}/allandactive`;
			},
			providesTags: () => {
				return ["CompanyMarts"];
			},
		}),
		setActiveMarts: builder.mutation<
			void,
			{ companyId: string; martIds: number[] }
		>({
			query({ companyId, martIds }) {
				return {
					method: "POST",
					url: "/api/Marts/setactive",
					body: { CompanyId: companyId, SetAsActiveMart: martIds },
					responseHandler: "text",
				};
			},
			invalidatesTags: ["CompanyMarts"],
		}),

		movePump: builder.mutation<void, { pumpId: string; tenantId: string }>({
			query: ({ tenantId, pumpId }) => {
				return {
					url: `Pumps/move/pumpid/${pumpId}/totenantid/${tenantId}`,
					method: "POST",
				};
			},
			invalidatesTags: ["Pump"],
		}),

		getSimpleUsers: builder.query<
			{ name: string; email: string }[],
			{ companyId: string }
		>({
			query({ companyId }) {
				return `User/companies/${companyId}/assignableusers`;
			},
		}),

		getPumpTypes: builder.query<string[], void>({
			query: () => "Pumps/types",
		}),

		createFakePump: builder.mutation<
			void,
			{
				companyId: string;
				pumpType: string;
				legalEntityId?: string;
				name?: string;
			}
		>({
			query: ({ companyId, pumpType, legalEntityId, name }) => {
				const query = new URLSearchParams();
				if (legalEntityId) {
					query.set("lemasterkey", legalEntityId);
				}
				if (name) {
					query.set("lename", name);
				}
				return {
					method: "POST",
					url: `Pumps/fake-pump/${companyId}/${pumpType}?${query}`,
				};
			},
			invalidatesTags: ["Pump"],
		}),

		createExopenPlanningPump: builder.mutation<
			void,
			{ companyDomainId: string; leMasterKey: string }
		>({
			query: ({ companyDomainId, leMasterKey }) => ({
				method: "POST",
				url: `/Pumps/exopenplanning/${companyDomainId}/${leMasterKey}`,
			}),
			invalidatesTags: ["Pump"],
		}),

		createExopenDataModelApiPump: builder.mutation<
			void,
			{ companyDomainId: string }
		>({
			query: ({ companyDomainId }) => ({
				method: "POST",
				url: `/Pumps/exopendatamodelapi/${companyDomainId}`,
			}),
			invalidatesTags: ["Pump"],
		}),

		createExopenForecastPump: builder.mutation<
			void,
			{ companyDomainId: string; leMasterKey: string }
		>({
			query: ({ companyDomainId, leMasterKey }) => ({
				method: "POST",
				url: `/Pumps/exopenforecast/${companyDomainId}/${leMasterKey}`,
			}),
			invalidatesTags: ["Pump"],
		}),

		createEwr: builder.mutation<void, { companyId: string }>({
			query({ companyId }) {
				return {
					method: "POST",
					url: "/ewr",
					body: {
						companyId: Number(companyId),
					},
				};
			},
			invalidatesTags: ["EWR"],
		}),

		deployEwrPackage: builder.mutation<
			void,
			{ companyId: number; packageId: string }
		>({
			query({ companyId, packageId }) {
				return {
					method: "POST",
					url: "/ewr/packages",
					body: {
						companyId,
						packageId,
					},
				};
			},
			invalidatesTags: ["EWR"],
		}),

		addVismaNetPump: builder.mutation<
			void,
			{
				companyDomainId: string;
				tenantId: string;
				costCenterId: number | null;
				projectId: number | null;
			}
		>({
			query({ companyDomainId, tenantId, costCenterId, projectId }) {
				return {
					method: "POST",
					url: `/Pumps/vismanet/client-credentials/${companyDomainId}`,
					body: {
						tenantId,
						subAccountSegmentIds: {
							costCenterId,
							projectId,
						},
					},
				};
			},
			invalidatesTags: ["Pump"],
		}),

		addXledgerPump: builder.mutation<
			void,
			{
				token: string;
				companyId: string;
			}
		>({
			query({ token, companyId }) {
				return {
					method: "POST",
					url: `/Pumps/xledger/${companyId}`,
					body: {
						token,
					},
				};
			},
			invalidatesTags: ["Pump"],
		}),

		addBusinessCentralPump: builder.mutation<
			void,
			{
				companyDomainId: string;
				tenantId: string;
				clientId: string;
				clientSecret: string;
				environment: string;
				includedCompanies: Array<{
					includedCompanyId: string;
					dataConfig: {
						dimensionCostCenterId: string | null;
						dimensionProjectId: string | null;
					};
				}>;
			}
		>({
			query({
				companyDomainId,
				clientId,
				clientSecret,
				includedCompanies,
				tenantId,
				environment,
			}) {
				return {
					method: "POST",
					url: `/Pumps/businesscentral/${companyDomainId}`,
					body: {
						tenantId,
						clientId,
						clientSecret,
						environment,
						includedCompanies,
					},
				};
			},
			invalidatesTags: ["Pump"],
		}),

		listAvailableCompaniesForBusinessCentral: builder.query<
			{ name: string; id: string }[],
			{
				tenantId: string;
				clientId: string;
				clientSecret: string;
				environment: string;
			}
		>({
			query({ clientSecret, clientId, tenantId, environment }) {
				return {
					url: "/Pumps/businesscentral/list-available-companies",
					headers: {
						tenantId,
						clientId,
						clientSecret,
						environment,
					},
				};
			},
		}),

		listAvailableDimensionsForBusinessCentral: builder.query<
			Array<{
				externalCompanyId: string;
				dimensions: Array<{ name: string; id: string }>;
			}>,
			{
				tenantId: string;
				clientId: string;
				clientSecret: string;
				environment: string;
				externalCompanyIds: string[];
			}
		>({
			query({
				clientSecret,
				clientId,
				tenantId,
				environment,
				externalCompanyIds,
			}) {
				return {
					url: "/Pumps/businesscentral/list-available-dimensions",
					headers: {
						tenantId,
						clientId,
						clientSecret,
						environment,
						externalCompanyIds: externalCompanyIds.join(","),
					},
				};
			},
		}),

		addVitecPump: builder.mutation<
			void,
			{
				domain: string;
				username: string;
				password: string;
				integrationId: string;
				includedCompanyIds: string[];
				excludedCompanyIds: string[];
				companyDomainId: string;
			}
		>({
			query({
				domain,
				excludedCompanyIds,
				includedCompanyIds,
				integrationId,
				password,
				username,
				companyDomainId,
			}) {
				return {
					method: "POST",
					url: `/Pumps/vitec-cloud/${companyDomainId}`,
					body: {
						domain,
						username,
						password,
						integrationId,
						includedCompanyIds,
						excludedCompanyIds,
						ensureUniqueLeMasterKeyIds: false,
					},
				};
			},
			invalidatesTags: ["Pump"],
		}),

		getAvailableCompaniesForVitec: builder.query<
			{
				name: string;
				id: string;
			}[],
			{ username: string; password: string; domain: string }
		>({
			query({ domain, username, password }) {
				return {
					url: "/Pumps/vitec-cloud/list-available-companies",
					headers: {
						domain,
						username,
						password,
					},
				};
			},
		}),

		addTfsOfficePump: builder.mutation<
			void,
			{
				companyDomainId: string;
				username: string;
				password: string;
				exopenApplicationId: string;
				integrationClientId: string;
			}
		>({
			query({
				companyDomainId,
				exopenApplicationId,
				integrationClientId,
				password,
				username,
			}) {
				return {
					method: "POST",
					url: `/Pumps/tfsoffice/${companyDomainId}`,
					body: {
						credentials: {
							username,
							password,
							exopenApplicationId,
						},
						integrationClientId,
					},
				};
			},
			invalidatesTags: ["Pump"],
		}),

		listAvailableCompaniesForTfsOffice: builder.query<
			{ name: string; id: string }[],
			{ username: string; password: string; exopenApplicationId: string }
		>({
			query({ username, password, exopenApplicationId }) {
				return {
					url: "/Pumps/tfsoffice/list-available-companies",
					headers: {
						username,
						password,
						exopenApplicationId,
					},
				};
			},
		}),

		savePump: builder.mutation<
			void,
			{ pumpConfig: PumpConfigUpsertPumpSetting }
		>({
			query({ pumpConfig }) {
				return {
					method: "POST",
					url: `/Pumps/${pumpConfig.pumpType!.toLowerCase()}`,
					body: pumpConfig,
				};
			},
			invalidatesTags: ["Pump"],
		}),

		getSystemUsers: builder.query<User[], void>({
			query() {
				return { url: "User/users" };
			},
			providesTags: ["SystemUser"],
		}),

		deleteUserFromSystem: builder.mutation<void, { email: string }>({
			query({ email }) {
				return {
					url: "User/user",
					headers: {
						userEmail: email,
					},
					method: "DELETE",
				};
			},
			invalidatesTags: ["SystemUser"],
		}),

		addUserToCompany: builder.mutation<
			void,
			{ companyDomainId: string; email: string }
		>({
			query({ email, companyDomainId }) {
				return {
					method: "PUT",
					url: "User/Companies",
					body: {
						userEmail: email,
						companyId: Number(companyDomainId),
					},
				};
			},
			invalidatesTags: ["User"],
		}),

		changeCompanyType: builder.mutation<
			void,
			{ companyDomainId: string; type: string }
		>({
			query({ companyDomainId, type }) {
				return {
					method: "PUT",
					url: `api/v2/companies/${companyDomainId}/type`,
					body: {
						type,
					},
				};
			},
			invalidatesTags: ["Company"],
		}),

		getCompanyConfigDlm: builder.query<
			DlmCompanyConfig,
			{ companyDomainId: string }
		>({
			query({ companyDomainId }) {
				return `api/v1/datalayer/manifestation/${companyDomainId}/config`;
			},
		}),

		getDlmDataObjectTypes: builder.query<DlmDataObjectTypes[], void>({
			query() {
				return "api/v1/datalayer/manifestation/data-object-types";
			},
		}),

		getDlmRawSystems: builder.query<string[], void>({
			query() {
				return "api/v1/datalayer/manifestation/available-raw-systems";
			},
		}),

		setCompanyDefaultDestinationDlm: builder.mutation<
			void,
			{
				companyDomainId: string;
				rawMartEnabled: boolean;
				financeMartEnabled: boolean;
			}
		>({
			query({ companyDomainId, financeMartEnabled, rawMartEnabled }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/config/default`,
					body: {
						financeMartEnabled,
						rawMartEnabled,
					},
				};
			},
		}),

		deleteCompanyDestinationDlm: builder.mutation<
			void,
			{
				companyDomainId: string;
			}
		>({
			query({ companyDomainId }) {
				return {
					method: "DELETE",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/config`,
				};
			},
		}),

		setLegalEntityConfigurationDlm: builder.mutation<
			void,
			{
				companyDomainId: string;
				legalEntityMartSettings: DlmLegalEntityMartConfig[];
			}
		>({
			query({ companyDomainId, legalEntityMartSettings }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/set-marts`,
					body: legalEntityMartSettings,
				};
			},
		}),

		setIntegrationRawConfigurationDlm: builder.mutation<
			void,
			{
				companyDomainId: string;
				integrationRawMartSettings: DlmRawMartConfig[];
			}
		>({
			query({ companyDomainId, integrationRawMartSettings }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/set-raw-integrations`,
					body: integrationRawMartSettings,
				};
			},
		}),

		runFullLoadDlmFinanceForCompanyDomain: builder.mutation<
			void,
			{
				companyDomainId: string;
				dataObjectType?: string | undefined;
			}
		>({
			query({ companyDomainId, dataObjectType }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/finance/full-load`,
					params: { dataObjectType },
				};
			},
		}),

		runFullLoadDlmRawForCompanyDomain: builder.mutation<
			void,
			{
				companyDomainId: string;
				dataObjectType?: string | undefined;
			}
		>({
			query({ companyDomainId, dataObjectType }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/raw/full-load`,
					params: { dataObjectType },
				};
			},
		}),

		runFullLoadDlmFinanceForLegalEntity: builder.mutation<
			void,
			{
				companyDomainId: string;
				legalEntityId: string;
				dataObjectType?: string | undefined;
			}
		>({
			query({ companyDomainId, legalEntityId, dataObjectType }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/finance/full-load/${legalEntityId}`,
					params: { dataObjectType },
				};
			},
		}),

		runFullLoadDlmRawForIntegration: builder.mutation<
			void,
			{
				companyDomainId: string;
				integrationId: number;
				dataObjectType?: string | undefined;
			}
		>({
			query({ companyDomainId, integrationId, dataObjectType }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/raw/full-load/${integrationId}`,
					params: { dataObjectType },
				};
			},
		}),

		resetDatabaseDlmFinance: builder.mutation<
			void,
			{
				companyDomainId: string;
			}
		>({
			query({ companyDomainId }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/finance/reset-db`,
				};
			},
		}),

		resetDatabaseDlmRaw: builder.mutation<
			void,
			{
				companyDomainId: string;
				system: string;
			}
		>({
			query({ companyDomainId, system }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/raw/reset-db/${system}`,
				};
			},
		}),

		createMissingFinanceTablesDlm: builder.mutation<
			void,
			{
				companyDomainId: string;
			}
		>({
			query({ companyDomainId }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/finance/create-missing-tables`,
				};
			},
		}),

		createMissingRawTablesDlm: builder.mutation<
			void,
			{
				companyDomainId: string;
				system: string;
			}
		>({
			query({ companyDomainId, system }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/raw/create-missing-tables/${system}`,
				};
			},
		}),

		setFinanceDestinationSchemaDlm: builder.mutation<
			void,
			{
				companyDomainId: string;
				destinationSchema: string;
			}
		>({
			query({ companyDomainId, destinationSchema }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/finance/destination-schema/${destinationSchema}`,
				};
			},
		}),

		setRawSchemaPrefixDlm: builder.mutation<
			void,
			{
				companyDomainId: string;
				schemaName: string;
			}
		>({
			query({ companyDomainId, schemaName }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/raw/raw-schema-prefix`,
					params: { schemaName },
				};
			},
		}),

		forceWriteParquetDlmFinance: builder.mutation<
			void,
			{
				companyDomainId: string;
				legalEntityId: string;
			}
		>({
			query({ companyDomainId, legalEntityId }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/force-write-parquet/finance/${legalEntityId}`,
				};
			},
		}),

		forceWriteParquetDlmRaw: builder.mutation<
			void,
			{
				companyDomainId: string;
				integrationId: string;
			}
		>({
			query({ companyDomainId, integrationId }) {
				return {
					method: "POST",
					url: `api/v1/datalayer/manifestation/${companyDomainId}/force-write-parquet/raw/${integrationId}`,
				};
			},
		}),
		getTemplateApps: builder.query<TemplateApp[], void>({
			query() {
				return "api/v1/datalayer/pbi-template-app";
			},
		}),
		getTemplateAppByName: builder.query<
			PbiAppInstallation,
			{
				companyDomainId: string;
				appName:
					| "ExopenFinancialReportTemplate"
					| "ExopenCrmReportTemplate"
					| "ExopenFinancialAnalyticsTemplate";
			}
		>({
			query({ companyDomainId, appName }) {
				return `api/v1/datalayer/${companyDomainId}/pbi/template-app/${appName}?dataStorageLocation=Database`;
			},
		}),

		putTemplateApps: builder.mutation<void, { templateApp: TemplateApp }>({
			query({ templateApp }) {
				return {
					method: "PUT",
					url: `api/v1/datalayer/pbi-template-app/${templateApp.name}`,
					body: {
						appId: templateApp.appId,
						packageKey: templateApp.packageKey,
						ownerTenantId: templateApp.ownerTenantId,
					},
				};
			},
		}),

		getPublicFile: builder.query<Blob, { fileName: string }>({
			query({ fileName }) {
				return {
					url: `Storage/public/${fileName}`,
					responseHandler: (response) => response.blob(),
				};
			},
		}),

		getDatabaseParameters: builder.query<
			DatabaseConnection,
			{ companyDomainId: string }
		>({
			query({ companyDomainId }) {
				return `api/v1/datalayer/${companyDomainId}/pbi/database-parameters`;
			},
		}),

		getDatabaseReaderUser: builder.query<
			DatabaseUser,
			{ companyDomainId: string }
		>({
			query({ companyDomainId }) {
				return `api/v1/datalayer/${companyDomainId}/database-reader-user`;
			},
		}),

		resetDatabaseReaderUser: builder.mutation<
			DatabaseUser,
			{ companyDomainId: string }
		>({
			query({ companyDomainId }) {
				return {
					method: "PUT",
					url: `api/v1/datalayer/${companyDomainId}/database-reader-user/generate-password`,
				};
			},
		}),
	}),
});

export const {
	useListAvailableCompaniesForTfsOfficeQuery,
	useAddTfsOfficePumpMutation,
	useListAvailableCompaniesForBusinessCentralQuery,
	useListAvailableDimensionsForBusinessCentralQuery,
	useAddBusinessCentralPumpMutation,
	useAddXledgerPumpMutation,
	useSavePumpMutation,
	useGetAvailableObjectsForAccessGroupsQuery,
	useGetAccessGroupsQuery,
	useUpdateUserMutation,
	useDeleteUserMutation,
	useInviteUserMutation,
	useGetCompanyUsersQuery,
	useValidateSieFileMutation,
	useApproveSieFileMutation,
	useDeleteSiePeriodMutation,
	useActivateDataLayerMutation,
	useDeactivateDataLayerMutation,
	useGetDataLayerPbiUrlsQuery,
	useGetDataLayerStatusQuery,
	useGetDataLayerPbiTicketQuery,
	useGetPipelineRunsQuery,
	useStartMainPipelineMutation,
	useStopPipelinesMutation,
	useGetAllCompaniesQuery,
	useGetCompanyQuery,
	useCreateCompanyMutation,
	useGetCurrentUserQuery,
	useCreateVismaNetCompanyMutation,
	useGetVismaNetDimensionsQuery,
	useGetVismaNetBranchQuery,
	useGetLegalEntitiesQuery,
	useMovePumpMutation,
	useGetSimpleUsersQuery,
	useGetPumpTypesQuery,
	useCreateFakePumpMutation,
	useCreateExopenDataModelApiPumpMutation,
	useCreateExopenForecastPumpMutation,
	useCreateExopenPlanningPumpMutation,
	useSetActiveMartsMutation,
	useGetCompanyMartsQuery,
	useDeleteAccessGroupMutation,
	useAddAccessGroupMutation,
	useEditAccessGroupMutation,
	useDeleteCompanyMutation,
	useDeletePumpMutation,
	useDeleteLegalEntityMutation,
	useCreateEwrMutation,
	useAddVismaNetPumpMutation,
	useDeleteUserFromSystemMutation,
	useGetSystemUsersQuery,
	useAddUserToCompanyMutation,
	useChangeCompanyTypeMutation,
	useGetCompanyConfigDlmQuery,
	useGetDlmDataObjectTypesQuery,
	useGetDlmRawSystemsQuery,
	useSetCompanyDefaultDestinationDlmMutation,
	useDeleteCompanyDestinationDlmMutation,
	useSetLegalEntityConfigurationDlmMutation,
	useSetIntegrationRawConfigurationDlmMutation,
	useRunFullLoadDlmFinanceForCompanyDomainMutation,
	useRunFullLoadDlmRawForCompanyDomainMutation,
	useRunFullLoadDlmFinanceForLegalEntityMutation,
	useRunFullLoadDlmRawForIntegrationMutation,
	useResetDatabaseDlmFinanceMutation,
	useResetDatabaseDlmRawMutation,
	useCreateMissingFinanceTablesDlmMutation,
	useCreateMissingRawTablesDlmMutation,
	useSetFinanceDestinationSchemaDlmMutation,
	useSetRawSchemaPrefixDlmMutation,
	useForceWriteParquetDlmFinanceMutation,
	useForceWriteParquetDlmRawMutation,
	useAddVitecPumpMutation,
	useLazyGetAvailableCompaniesForVitecQuery,
	useGetTemplateAppsQuery,
	usePutTemplateAppsMutation,
	useDeployEwrPackageMutation,
	useRecreatePipelinesMutation,
	useLazyGetPublicFileQuery,
	useLazyGetDatabaseReaderUserQuery,
	useGetDatabaseReaderUserQuery,
	useResetDatabaseReaderUserMutation,
	useGetDatabaseParametersQuery,
	useGetTemplateAppByNameQuery,
	useGetDataLayerPbiStorageParametersQuery,
} = exopenApi;
